<template>
  <div class="">
    <el-card class="box-card">
      <div class="buyprop">
        <el-button
          type="primary"
          icon="el-icon-circle-plus"
          @click="accoundSon('add')"
          >新增子账号</el-button
        >
      </div>
      <div class="" style="min-height: 500px">
        <el-table
          :data="tableData"
          v-loading="table_loading"
          :show-header="false"
          element-table_loading-text="加载中"
          class="boderline"
        >
          <el-table-column type="index" width="80"></el-table-column>
          <el-table-column prop="displayName"></el-table-column>
          <el-table-column prop="phone"></el-table-column>
          <el-table-column prop="jobName"></el-table-column>
          <el-table-column prop="loginEmail"></el-table-column>
          <el-table-column width="300">
            <template slot-scope="scope">
              <div class="caozuo">
                <el-button type="text" @click="accoundSon('edit', scope.row)"
                  >编辑</el-button
                >
                <el-button type="text" @click="del(scope.row.id)"
                  >删除</el-button
                >
                <el-button type="text" @click="changeAdmin(scope.row)"
                  >转移权限</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 删除弹窗 -->
    <el-dialog
      :visible.sync="deleteDialogVisible"
      width="600px"
      center
      class="delete_box"
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="" style="padding: 50px 30px 30px 30px; text-align: center">
        <div class="db-img">
          <img src="../../assets/images/position/Dialog_delete.png" />
        </div>
        <div class="db-sure font22">
          一旦删除将不保留该子账号发布的职位以及相关操作
          建议先更改子账号登录密码
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="qx" @click="deleteDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="delCommit">确认</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 权限转移 -->
    <el-dialog
      :visible.sync="qxDialogVisible"
      width="600px"
      center
      class="delete_box"
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="" style="padding: 50px 30px 30px 30px; text-align: center">
        <div class="db-img">
          <img class="quanxian" src="../../assets/images/common/qx.png" />
        </div>
        <div class="db-sure font22">权限一旦转移将无法对子账号进行编辑</div>
        <span slot="footer" class="dialog-footer">
          <el-button class="qx" @click="qxDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="surezhuanyi()">确认转移</el-button>
        </span>
      </div>
    </el-dialog>

    <!-- 编辑子账号 -->
    <editaccount></editaccount>

    <!-- 账号转移成功弹窗 -->
    <!-- :close-on-click-modal="false" -->
    <el-dialog
      :visible.sync="zysuccessDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="ub-img">
          <img src="../../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">账号转移成功</div>
        <div class="ub-fu font16">您已成为子账号，请重新登录</div>
        <!-- <div class="buy-btn">
					<el-button type="primary" @click="zysuccessDialogVisible=false">确定</el-button>
				</div> -->
      </div>
    </el-dialog>

    <!-- 安全验证 -->
    <safetyverification @change="changestuts"></safetyverification>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
import editaccount from "/src/components/user/editaccount.vue"; //搜素的简历详情
import safetyverification from "/src/components/user/safetyverification.vue"; //搜素的简历详情
export default {
  components: {
    editaccount,
    safetyverification,
  },
  data() {
    return {
      table_loading: false,
      tableData: [],
      delId: "",
      changeData: "",
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
        count: 1,
        dtos: [],
      },
      deleteDialogVisible: false, //删除弹窗
      qxDialogVisible: false, //权限转移弹窗
      zysuccessDialogVisible: false, //转移成功弹窗
    };
  },
  activated() {
    this.fetchData(this.pageInfo.pageNum);
    this.getList();
  },
  methods: {
    getList() {
      this.$api.getAccountList("get").then((res) => {
        this.tableData = res.data;
      });
    },
    accoundSon(type, data) {
      let config = {
        show: true,
        type: type,
        data: data,
        callback: () => {
          this.$api.getAccountList("get").then((res) => {
            this.tableData = res.data;
          });
        },
      };
      Bus.$emit("editaccountstuts", config);
    },
    del(id) {
      this.deleteDialogVisible = true;
      this.delId = id;
    },
    delCommit() {
      this.$api.delAccount("post", { id: this.delId }).then(() => {
        this.deleteDialogVisible = false;
        this.getList();
      });
    },
    changeAdmin(data) {
      this.qxDialogVisible = true;
      this.changeData = data;
    },
    changestuts(e) {
      // console.log("接收到的值", e);
      this.zysuccessDialogVisible = e;
    },
    fetchData(page = 1) {
      let vm = this;
      // vm.table_loading = true
      vm.pageInfo.pageNum = page;
      // vm.formInline.pageNum = page

      vm.table_loading = false;
    },
    tobuy() {
      this.$router.push("/propsmall");
    },
    edit(id) {
      // console.log(id);
      let data = {
        show: true,
        id: id,
      };
      Bus.$emit("editaccountstuts", data);
    },
    closebox() {
      this.zysuccessDialogVisible = false;
    },
    surezhuanyi() {
      this.qxDialogVisible = false;
      let data = {
        show: true,
        data: this.changeData,
      };
      Bus.$emit("safetystuts", data);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
  padding: 0px 30px 30px 30px;
}
.buyprop {
  margin: 30px 0;
  /deep/ .el-button {
    border-radius: 0px;
    width: 135px;
    background: #00bcff;
    border: none;
  }
}
.boderline {
  width: 100%;
  border-right: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
}
/deep/.el-table td,
/deep/.el-table th {
  text-align: center;
  color: #111111;
  font-size: 14px;
}
.zhang {
  color: #666666;
}
.caozuo {
  .el-button {
    color: #00bcff;
  }
}
.delete_box {
  line-height: 4;
  padding: 30px 0;
  // /deep/.el-card__body{
  // 	padding: 50px 30px 30px 30px !important;
  // }
  .db-img {
    text-align: center;
    img {
      width: 53px;
      height: 57px;
    }
    .quanxian {
      width: 50px;
      height: 56px;
    }
  }
  .db-sure {
    text-align: center;
    color: #111111;
    line-height: 1.5;
    padding: 0 50px;
  }
  .db-ts {
    text-align: center;
    color: #666666;
    line-height: 1.5;
  }
  .dialog-footer {
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      margin-top: 40px;
      border-radius: 0px;
      background: #00bcff;
      border: none;
      width: 200px;
      height: 40px;
    }
    .qx {
      background-color: #fff;
      border: 1px solid #00bcff;
      color: #00bcff;
      margin-right: 10px;
    }
  }
}
.updown_box {
  line-height: 2;
  padding: 40px 0;
  position: relative;
  .closeicon {
    position: absolute;
    top: -60px;
    right: 0;
    font-size: 26px;
    color: #f3f3f3;
  }
  .ub-img {
    text-align: center;
    margin-top: 30px;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
    margin-top: 20px;
  }
  .ub-fu {
    text-align: center;
    color: #666666;
  }
  .buy-btn {
    text-align: center;
    margin-top: 40px;
    /deep/ .el-button {
      border-radius: 0px;
      width: 400px;
      height: 40px;
      background-color: #00bcff;
      border: none;
    }
  }
}
</style>
