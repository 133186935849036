<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">安全验证</div>
        <div class="updatabox">
          <div class="tit font16">为了账号的安全性我们将会进行验证</div>
          <div class="zhu">
            <div class="" style="display: flex; align-items: center">
              <div class="zhu-left font14">
                <div class="">主账号手机验证：</div>
                <div class="">{{ getPhone(userInfo.phone) }}</div>
              </div>
              <div class="input-box">
                <div
                  class="input-content"
                  @keydown="keydownMain"
                  @keyup="keyupMain"
                  @paste="pasteMain"
                  @mousewheel="mousewheelMain"
                  @input="inputEventMain"
                >
                  <input
                    max="9"
                    min="0"
                    maxlength="1"
                    data-index="0"
                    v-model.trim.number="mainInput[0]"
                    type="number"
                    ref="firstinput"
                  />
                  <input
                    max="9"
                    min="0"
                    maxlength="1"
                    data-index="1"
                    v-model.trim.number="mainInput[1]"
                    type="number"
                  />
                  <input
                    max="9"
                    min="0"
                    maxlength="1"
                    data-index="2"
                    v-model.trim.number="mainInput[2]"
                    type="number"
                  />
                  <input
                    max="9"
                    min="0"
                    maxlength="1"
                    data-index="3"
                    v-model.trim.number="mainInput[3]"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div class="zhu-right">
              <el-button
                class="btn-orange"
                :disabled="mainDisable"
                @click="getMainCode"
                plain
                >{{ mainValiBtn }}</el-button
              >
            </div>
          </div>
          <div class="zhu">
            <div class="" style="display: flex; align-items: center">
              <div class="zhu-left font14">
                <div class="">子账号手机验证：</div>
                <div class="">{{ getPhone(changeData.phone) }}</div>
              </div>
              <div class="input-box">
                <div
                  class="input-content"
                  @keydown="keydown"
                  @keyup="keyup"
                  @paste="paste"
                  @mousewheel="mousewheel"
                  @input="inputEvent"
                >
                  <input
                    max="9"
                    min="0"
                    maxlength="1"
                    data-index="0"
                    v-model.trim.number="input[0]"
                    type="number"
                    ref="firstinput"
                  />
                  <input
                    max="9"
                    min="0"
                    maxlength="1"
                    data-index="1"
                    v-model.trim.number="input[1]"
                    type="number"
                  />
                  <input
                    max="9"
                    min="0"
                    maxlength="1"
                    data-index="2"
                    v-model.trim.number="input[2]"
                    type="number"
                  />
                  <input
                    max="9"
                    min="0"
                    maxlength="1"
                    data-index="3"
                    v-model.trim.number="input[3]"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div class="zhu-right">
              <el-button
                class="btn-orange"
                :disabled="disabled"
                @click="getCode"
                plain
                >{{ valiBtn }}</el-button
              >
            </div>
          </div>
          <div class="foter-btn">
            <el-button class="btn-orange" @click="sure()">确定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      changeData: {},
      userInfo: {},
      main: {
        one: "",
        two: "",
        three: "",
        four: "",
      },
      pasteResult: [],
      code: "",

      codezhu: "",
      mainValiBtn: "获取验证码",
      valiBtn: "获取验证码",
      disabled: false,
      mainDisable: false,
    };
  },
  // props: ['code'],
  mounted() {
    Bus.$on("safetystuts", (data) => {
      // console.log('编辑接受到', data);
      this.dialogVisible = data.show;
      this.changeData = data.data;
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      // this.id= data.id;
    });
    // 等待dom渲染完成，在执行focus,否则无法获取到焦点
    // this.$nextTick(() => {
    // 	this.$refs.firstinput.focus();
    // });
  },

  computed: {
    input() {
      if (this.code && Array.isArray(this.code) && this.code.length === 4) {
        return this.code;
      } else if (/^\d{6}$/.test(this.code.toString())) {
        return this.code.toString().split("");
      } else if (this.pasteResult.length === 4) {
        return this.pasteResult;
      } else {
        return new Array(4);
      }
    },
    mainInput() {
      if (
        this.codezhu &&
        Array.isArray(this.codezhu) &&
        this.codezhu.length === 4
      ) {
        return this.codezhu;
      } else if (/^\d{6}$/.test(this.codezhu.toString())) {
        return this.codezhu.toString().split("");
      } else if (this.pasteResult.length === 4) {
        return this.pasteResult;
      } else {
        return new Array(4);
      }
    },
  },
  methods: {
    getPhone(num) {
      let phone = "";
      if (num) {
        let first = num.slice(0, 3);
        let content = "****";
        let last = num.slice(7, 11);
        phone = first + content + last;
      }
      return phone;
    },
    sure() {
      let code = "";
      let codemain = "";
      this.mainInput.map((item) => {
        codemain += item;
      });
      this.input.map((item) => {
        code += item;
      });
      let config = {
        code: codemain,
        companyUserId: this.changeData.id,
        userCode: code,
      };
      // console.log(config);
      this.$api.changeAdmin("post", config).then(() => {
        // console.log(res);
        // this.dialogVisible=false;
        // this.$emit('change',true)
        this.$message({
          message: "您已成为子账号，请重新登录",
          type: "success",
        });
        this.$api.outlogin("post").then(() => {
          window.sessionStorage.clear;

          this.$router.push("/login");
          this.$IM.outLogin();
        });
      });
      // this.dialogVisible=false;
      // this.$emit('change',true)
    },
    test() {
      // 获取到输入的验证码
      console.log(this.input);
    },
    getMainCode() {
      this.tackMainBtn();
      // this.tackBtn(); //验证码倒数60秒
      let fd = {
        phone: this.userInfo.phone,
        type: 3,
      };
      this.$api.phonemsg("post", fd).then(() => {});
    },
    getCode() {
      this.tackBtn(); //验证码倒数60秒
      let fd = {
        phone: this.changeData.phone,
        type: 3,
      };
      this.$api.phonemsg("post", fd).then(() => {});
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
    tackMainBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.mainValiBtn = "获取验证码";
          this.mainDisable = false;
        } else {
          this.mainDisable = true;
          this.mainValiBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
    closebox() {
      this.dialogVisible = false;
      // Bus.$emit('reportdstuts', false)
    },
    // 解决一个输入框输入多个字符
    inputEventMain(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      el.value = el.value
        .replace(/Digit|Numpad/i, "")
        .replace(/1/g, "")
        .slice(0, 1);
      this.$set(this.mainInput, index, el.value);
    },
    keydownMain(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      if (e.key === "Backspace") {
        if (this.mainInput[index].length > 0) {
          this.$set(this.mainInput, index, "");
        } else {
          if (el.previousElementSibling) {
            el.previousElementSibling.focus();
            this.$set(this.mainInput, index - 1, "");
          }
        }
      } else if (e.key === "Delete") {
        if (this.mainInput[index].length > 0) {
          this.$set(this.mainInput, index, "");
        } else {
          if (el.nextElementSibling) {
            this.$set(this.mainInput, (index = 1), "");
          }
        }
        if (el.nextElementSibling) {
          el.nextElementSibling.focus();
        }
      } else if (e.key === "Home") {
        el.parentElement.children[0] && el.parentElement.children[0].focus();
      } else if (e.key === "End") {
        el.parentElement.children[this.mainInput.length - 1] &&
          el.parentElement.children[this.mainInput.length - 1].focus();
      } else if (e.key === "ArrowLeft") {
        if (el.previousElementSibling) {
          el.previousElementSibling.focus();
        }
      } else if (e.key === "ArrowRight") {
        if (el.nextElementSibling) {
          el.nextElementSibling.focus();
        }
      } else if (e.key === "ArrowUp") {
        if (this.mainInput[index] * 1 < 9) {
          this.$set(
            this.mainInput,
            index,
            (this.mainInput[index] * 1 + 1).toString()
          );
        }
      } else if (e.key === "ArrowDown") {
        if (this.mainInput[index] * 1 > 0) {
          this.$set(
            this.mainInput,
            index,
            (this.mainInput[index] * 1 - 1).toString()
          );
        }
      }
    },
    keyupMain(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      // 解决输入e的问题
      el.value = el.value
        .replace(/Digit|Numpad/i, "")
        .replace(/1/g, "")
        .slice(0, 1);
      if (/Digit|Numpad/i.test(e.code)) {
        // 必须在这里符直，否则输入框会是空值
        this.$set(this.mainInput, index, e.code.replace(/Digit|Numpad/i, ""));
        el.nextElementSibling && el.nextElementSibling.focus();
        if (index === 3) {
          if (this.mainInput.join("").length === 4) {
            document.activeElement.blur();
            this.$emit("complete", this.mainInput);
          }
        }
      } else {
        if (this.mainInput[index] === "") {
          this.$set(this.mainInput, index, "");
        }
      }
    },
    mousewheelMain(e) {
      var index = e.target.dataset.index;
      if (e.wheelDelta > 0) {
        if (this.mainInput[index] * 1 < 9) {
          this.$set(
            this.mainInput,
            index,
            (this.mainInput[index] * 1 + 1).toString()
          );
        }
      } else if (e.wheelDelta < 0) {
        if (this.mainInput[index] * 1 > 0) {
          this.$set(
            this.mainInput,
            index,
            (this.mainInput[index] * 1 - 1).toString()
          );
        }
      } else if (e.key === "Enter") {
        if (this.mainInput.join("").length === 4) {
          document.activeElement.blur();
          this.$emit("complete", this.mainInput);
        }
      }
    },
    pasteMain(e) {
      // 当进行粘贴时
      e.clipboardData.items[0].getAsString((str) => {
        if (str.toString().length === 4) {
          this.pasteResult = str.split("");
          document.activeElement.blur();
          this.$emit("complete", this.mainInput);
          this.pasteResult = [];
        } else {
          // 如果粘贴内容不合规，清除所有内容
          this.mainInput[0] = new Array(4);
        }
      });
    },
    inputEvent(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      el.value = el.value
        .replace(/Digit|Numpad/i, "")
        .replace(/1/g, "")
        .slice(0, 1);
      this.$set(this.input, index, el.value);
    },
    keydown(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      if (e.key === "Backspace") {
        if (this.input[index].length > 0) {
          this.$set(this.input, index, "");
        } else {
          if (el.previousElementSibling) {
            el.previousElementSibling.focus();
            this.$set(this.input, index - 1, "");
          }
        }
      } else if (e.key === "Delete") {
        if (this.input[index].length > 0) {
          this.$set(this.input, index, "");
        } else {
          if (el.nextElementSibling) {
            this.$set(this.input, (index = 1), "");
          }
        }
        if (el.nextElementSibling) {
          el.nextElementSibling.focus();
        }
      } else if (e.key === "Home") {
        el.parentElement.children[0] && el.parentElement.children[0].focus();
      } else if (e.key === "End") {
        el.parentElement.children[this.input.length - 1] &&
          el.parentElement.children[this.input.length - 1].focus();
      } else if (e.key === "ArrowLeft") {
        if (el.previousElementSibling) {
          el.previousElementSibling.focus();
        }
      } else if (e.key === "ArrowRight") {
        if (el.nextElementSibling) {
          el.nextElementSibling.focus();
        }
      } else if (e.key === "ArrowUp") {
        if (this.input[index] * 1 < 9) {
          this.$set(this.input, index, (this.input[index] * 1 + 1).toString());
        }
      } else if (e.key === "ArrowDown") {
        if (this.input[index] * 1 > 0) {
          this.$set(this.input, index, (this.input[index] * 1 - 1).toString());
        }
      }
    },
    keyup(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      // 解决输入e的问题
      el.value = el.value
        .replace(/Digit|Numpad/i, "")
        .replace(/1/g, "")
        .slice(0, 1);
      if (/Digit|Numpad/i.test(e.code)) {
        // 必须在这里符直，否则输入框会是空值
        this.$set(this.input, index, e.code.replace(/Digit|Numpad/i, ""));
        el.nextElementSibling && el.nextElementSibling.focus();
        if (index === 3) {
          if (this.input.join("").length === 4) {
            document.activeElement.blur();
            this.$emit("complete", this.input);
          }
        }
      } else {
        if (this.input[index] === "") {
          this.$set(this.input, index, "");
        }
      }
    },
    mousewheel(e) {
      var index = e.target.dataset.index;
      if (e.wheelDelta > 0) {
        if (this.input[index] * 1 < 9) {
          this.$set(this.input, index, (this.input[index] * 1 + 1).toString());
        }
      } else if (e.wheelDelta < 0) {
        if (this.input[index] * 1 > 0) {
          this.$set(this.input, index, (this.input[index] * 1 - 1).toString());
        }
      } else if (e.key === "Enter") {
        if (this.input.join("").length === 4) {
          document.activeElement.blur();
          this.$emit("complete", this.input);
        }
      }
    },
    paste(e) {
      // 当进行粘贴时
      e.clipboardData.items[0].getAsString((str) => {
        if (str.toString().length === 4) {
          this.pasteResult = str.split("");
          document.activeElement.blur();
          this.$emit("complete", this.input);
          this.pasteResult = [];
        } else {
          // 如果粘贴内容不合规，清除所有内容
          this.input[0] = new Array(4);
        }
      });
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 20px;
  color: #111111;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.updatabox {
  padding: 50px 80px 30px 80px;
  .tit {
    margin-bottom: 40px;
    font-weight: 600;
  }
  .zhu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .zhu-left {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .input-box {
      margin-left: 20px;
      .input-content {
        width: 280px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          color: #00bcff;
          border: 0;
          outline: 0;
          border: 1px solid #00bcff;
          width: 44px;
          height: 44px;
          font-size: 20px;
          text-align: center;
          box-shadow: 0 2px 4px rgba(0, 188, 255, 0.2),
            0 0 6px rgba(0, 188, 255, 0.2);
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }
    .zhu-right {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // text-align: right;
      // margin-left: 50px;
      // /deep/ .el-button{
      // 	border-radius: 0px;
      // 	width: 100px;
      // 	color: #111111;
      // 	background-color: #F3F3F3;
      // 	height: 33px;
      // 	display: flex;
      // 	align-items: center;
      // 	justify-content: center;
      // }
    }
  }
}
.foter-btn {
  text-align: center;
  margin-top: 200px;
  /deep/ .el-button {
    border-radius: 0px;
    width: 430px;
    color: #ffffff;
    background: #00bcff;
    border: none;
  }
}
</style>
