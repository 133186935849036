<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">
          {{ type == "add" ? "新增" : "编辑" }}子账号
        </div>
        <div class="updatabox">
          <el-form
            :model="infoValidateForm"
            ref="infoValidateForm"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="登录邮箱："
              prop="loginEmail"
              :rules="[{ required: true, message: '请输入登录邮箱' }]"
            >
              <el-input
                v-model="infoValidateForm.loginEmail"
                :disabled="type == 'add' ? false : true"
                placeholder="请输入登录邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="登录密码："
              prop="password"
              :rules="[{ required: true, message: '请输入登录密码' }]"
            >
              <el-input
                v-model="infoValidateForm.password"
                :type="'password'"
                placeholder="请输入登录密码"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="姓名："
              prop="displayName"
              :rules="[{ required: true, message: '请输入姓名' }]"
            >
              <el-input
                v-model="infoValidateForm.displayName"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="性别："
              prop="sex"
              :rules="[{ required: true, message: '请选择性别' }]"
            >
              <el-select
                v-model="infoValidateForm.sex"
                placeholder="请选择性别"
                style="width: 440px"
              >
                <el-option label="男" :value="0"></el-option>
                <el-option label="女" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="职务："
              prop="jobName"
              :rules="[{ required: true, message: '请输入职务' }]"
            >
              <el-input
                v-model="infoValidateForm.jobName"
                placeholder="请输入职务"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="手机号："
              prop="phone"
              :rules="[{ required: true, message: '请输入手机号码' }]"
            >
              <el-input
                v-model="infoValidateForm.phone"
                :disabled="type == 'add' ? false : true"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item
              ><el-button
                type="primary"
                class="btn"
                @click="submitForm('infoValidateForm')"
                >确定</el-button
              ></el-form-item
            >
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      type: "",
      callback: null,
      infoValidateForm: {
        id: "",
        loginEmail: "",
        password: "",
        displayName: "",
        jobName: "",
        phone: "",
        sex: "",
      },
      id: "",
    };
  },
  mounted() {
    Bus.$on("editaccountstuts", (data) => {
      // console.log("编辑接受到", data);
      this.dialogVisible = data.show;
      this.type = data.type;
      this.callback = data.callback;
      this.infoValidateForm.id = data.type == "edit" ? data.data.id : "";
      this.infoValidateForm.loginEmail =
        data.type == "edit" ? data.data.loginEmail : "";
      this.infoValidateForm.password =
        data.type == "edit" ? data.data.password : "";
      this.infoValidateForm.displayName =
        data.type == "edit" ? data.data.displayName : "";
      this.infoValidateForm.jobName =
        data.type == "edit" ? data.data.jobName : "";
      this.infoValidateForm.phone = data.type == "edit" ? data.data.phone : "";
      this.infoValidateForm.sex = data.type == "edit" ? data.data.sex : "";
      // this.id= data.id;
    });
  },
  methods: {
    closebox() {
      this.dialogVisible = false;
      // Bus.$emit('reportdstuts', false)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.saveAccount("post", this.infoValidateForm).then((res) => {
            if (res.code != 10200) {
              this.$message({
                message: res.message,
                type: "error",
              });
              return;
            }
            this.dialogVisible = false;
            this.callback();
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 20px;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.updatabox {
  .demo-ruleForm {
    padding: 50px 80px 0px 50px;
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
      width: 430px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
  }
}
</style>
